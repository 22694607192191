<template>
  <div>
    <div
      class="flex flex-col gap-4 items-center mb-8 mt-10 py-5 px-8 rounded-sm md:flex-row lg:flex-col xl:flex-row"
      style="background-color: #f3f6ff"
    >
      <vector :src="assets.icons.sm.limit" />
      <div>
        <p class="text-11 leading-loose text-gray-600 mt-1">
          Increase your credit card limit, up to
          <span class="font-bold">N1Million</span> and standard loan limit, up
          to <span class="font-bold">N5Million</span>
        </p>
      </div>
      <button
        class="btn btn-blue-outline bg-transparent whitespace-no-wrap"
        @click.prevent="$refs.uploadStatement.open()"
      >
        Increase Limit
      </button>
    </div>

    <modal
      className="w-11/12 sm:w-443px rounded md:w-443px xl:w-500px"
      ref="uploadStatement"
    >
      <template>
        <div class="mt-4 md:-mx-6">
          <h3 class="text-12 mb-2">
            Automatic Bank statement Verification
          </h3>
          <div
            class="w-full p-5 rounded-sm bg-corporate-900 flex-row transition"
          >
            <h3 class="text-10 mb-2 mt-1 font-thin">
              To verify kindly select your salary / income bank account
            </h3>
            <div class="mt-4 mb-3">
              <div class="relative mb-3">
                <select
                  class="block appearance-none w-full bg-white text-12 text-black py-4 px-4 pr-8 rounded-sm leading-tight focus:outline-none"
                  @change="verifyBank"
                  v-model="bankName"
                  :disabled="bankStatementUploaded"
                >
                  <option
                    v-for="(option, i) in bankOptions"
                    :value="option.value"
                    :name="option.name"
                    :key="i"
                    >{{ option.value }}</option
                  >
                </select>
                <div
                  class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700"
                >
                  <svg
                    class="fill-current h-4 w-4"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                  >
                    <path
                      d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
                    />
                  </svg>
                </div>
              </div>
            </div>

            <h3
              class="mt-5 text-11 font-normal mb-2"
              v-if="checkMono || checkOkra"
            >
              Free Options
            </h3>
            <mono :publicKey="publicKey" @success="success">
              <div
                class="w-full p-5 mt-3 rounded-sm bg-corporate-900 border cursor-pointer flex justify-between transition duration-200"
                @click="methodSelected = 'Mono'"
                :class="[
                  methodSelected === 'Mono'
                    ? 'border-onboarding-600'
                    : 'border-onboarding-800',
                  mbsVerified || okraVerified || manualVerified ? 'hidden' : 'block',
                ]"
                v-if="checkMono"
              >
                <div class="flex flex-col text-onboarding-600 mb-2">
                  <h4 class="text-12 mt-2 font-bold">
                    Verify with Mono
                  </h4>
                  <h4 class="text-10 mt-1 font-thin">
                    Provide basic bank information to continue.
                  </h4>
                </div>
                <div
                  class="rounded-full mt-1 h-8 w-8 items-center flex justify-center"
                  :class="
                    methodSelected === 'Mono' || monoVerified
                      ? 'bg-credpal-400'
                      : 'bg-onboarding-800'
                  "
                >
                  <svg
                    width="5"
                    height="10"
                    viewBox="0 0 5 10"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1 1L4 5L1 9"
                      stroke="white"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </div>
              </div>
            </mono>
            <okra @success="okraSuccess">
              <div
                class="w-full p-5 mt-2 rounded-sm bg-corporate-900 border cursor-pointer flex justify-between transition duration-200"
                @click="methodSelected = 'Okra'"
                :class="[
                  methodSelected === 'Okra'
                    ? 'border-onboarding-600'
                    : 'border-onboarding-800',
                  monoVerified || mbsVerified || manualVerified ? 'hidden' : 'block',
                ]"
                v-if="checkOkra"
              >
                <div class="flex flex-col text-onboarding-600 mb-2">
                  <h4 class="text-12 mt-2 font-bold">
                    Verify with Okra
                  </h4>
                  <h4 class="text-10 mt-1 font-thin">
                    Provide basic bank information to continue.
                  </h4>
                </div>
                <div
                  class="rounded-full mt-1 h-8 w-8 items-center flex justify-center"
                  :class="
                    methodSelected === 'Okra' || okraVerified
                      ? 'bg-credpal-400'
                      : 'bg-onboarding-800'
                  "
                >
                  <svg
                    width="5"
                    height="10"
                    viewBox="0 0 5 10"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1 1L4 5L1 9"
                      stroke="white"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </div>
              </div>
            </okra>

            <div
              class="w-full p-5 mt-2 rounded-sm cursor-pointer bg-onboarding-900 text-white flex border justify-between transition duration-200"
              :class="[mobileVerified || manualVerified ? 'hidden' : 'block']"
              @click.prevent="
                $refs.verifyUSSD.open();
                methodSelected = 'ussd';
              "
              v-if="checkUssd"
            >
              <div class="flex flex-col mb-2">
                <h4 class="text-12 mt-2 font-bold">Verify with USSD</h4>
                <h4 class="text-10 mt-1 font-thin">
                  Use USSD Banking to send your bank statement.
                </h4>
              </div>
              <div
                class="rounded-full mt-1 h-8 w-8 items-center bg-gtb flex justify-center"
              >
                <svg
                  width="5"
                  height="10"
                  viewBox="0 0 5 10"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1 1L4 5L1 9"
                    stroke="white"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
            </div>

            <div
              class="w-full p-5 mt-2 rounded-sm cursor-pointer bg-onboarding-900 text-white flex border justify-between transition duration-200"
              :class="[ussdVerified || manualVerified ? 'hidden' : 'block']"
              @click.prevent="
                methodSelected = 'mobile';
                $refs.verifyMobile.open();
              "
              v-if="checkMobile"
            >
              <div class="flex flex-col mb-2">
                <h4 class="text-12 mt-2 font-bold">Verify with Mobile App</h4>
                <h4 class="text-10 mt-1 font-thin">
                  Use Mobile Banking App to send your bank statement.
                </h4>
              </div>
              <div
                class="rounded-full mt-1 h-8 w-8 bg-gtb items-center flex justify-center"
              >
                <svg
                  width="5"
                  height="10"
                  viewBox="0 0 5 10"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1 1L4 5L1 9"
                    stroke="white"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
            </div>

            <h3 class="mt-8 font-normal text-11 mb-2" v-if="checkMbs">
              You might be charge by your bank for this service.
            </h3>
            <div
              class="w-full p-5 mt-2 rounded-sm cursor-pointer flex bg-corporate-900 border justify-between transition duration-200"
              :class="[
                methodSelected === 'MBS'
                  ? 'border-onboarding-600'
                  : 'border-onboarding-800',
                monoVerified || okraVerified || manualVerified ? 'hidden' : 'block',
              ]"
              @click.prevent="
                $refs.verifyMBS.open();
                methodSelected = 'MBS';
              "
              v-if="checkMbs"
            >
              <div class="flex flex-col text-onboarding-600 mb-2">
                <h4 class="text-12 mt-2 font-bold">
                  Verify with MBS
                </h4>
                <h4 class="text-10 mt-1 font-thin">
                  Provide basic bank information to continue.
                </h4>
              </div>
              <div
                class="rounded-full mt-1 h-8 w-8 items-center flex justify-center"
                :class="
                  methodSelected === 'MBS' || mbsVerified
                    ? 'bg-credpal-400'
                    : 'bg-onboarding-800'
                "
              >
                <svg
                  width="5"
                  height="10"
                  viewBox="0 0 5 10"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1 1L4 5L1 9"
                    stroke="white"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
            </div>

            <h3 class="mt-5 text-12 mb-2" v-if="bankName">
              Manual
            </h3>

            <h3 class="mt-2 font-normal text-11 mb-2" v-if="bankName">
              Can't find your bank? Use manual upload.
            </h3>
            <div
              class="w-full p-5 mt-2 rounded-sm cursor-pointer flex bg-corporate-900 border justify-between transition duration-200"
              @click="
                $refs.uploadBankStatement.open();
                methodSelected = 'upload';
              "
              :class="[
                methodSelected === 'upload'
                  ? 'border-onboarding-600'
                  : 'border-onboarding-800',
                mbsVerified || monoVerified || okraVerified || ussdVerified || mobileVerified
                  ? 'hidden'
                  : 'block',
              ]"
              v-if="bankUpload"
            >
              <div class="flex flex-col text-onboarding-600 mb-2">
                <h4 class="text-12 mt-2 font-bold">
                  Verify with Bank Statement
                </h4>
                <h4 class="text-10 mt-1 font-thin">
                  Provide a 6 months bank statement instead.
                </h4>
              </div>
              <div
                class="rounded-full mt-1 h-8 w-8 items-center flex justify-center"
                :class="
                  methodSelected === 'upload'
                    ? 'bg-credpal-400'
                    : 'bg-onboarding-800'
                "
              >
                <svg
                  width="5"
                  height="10"
                  viewBox="0 0 5 10"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1 1L4 5L1 9"
                    stroke="white"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
            </div>
          </div>
        </div>
      </template>
    </modal>

    <modal className="w-11/12 sm:w-400px rounded md:w-400px" ref="verifyUSSD">
      <div class="lg:-mx-8">
        <h1 class="text-18 text-left font-bold mb-2">
          Verify with USSD
        </h1>

        <div class="rounded-sm bg-onboarding-900 text-white flex flex-row p-3">
          <div class="rounded-full h-6 w-6 bg-gtb"></div>
          <p class="text-10 mt-1 ml-3">
            Follow the steps below to verify your account.
          </p>
        </div>

        <div
          class="rounded-sm bg-gtbonboarding-100 text-center mt-4 pt-4 h-100px"
        >
          <p class="text-12">Dial</p>
          <h4 class="text-onboarding-900 text-32 mt-2">*737*51*33#</h4>
        </div>

        <div
          class="rounded-sm bg-gtbonboarding-200 mt-4 pt-4 text-center h-85px"
        >
          <p class="text-12">Then enter the email address below</p>
          <h4 class="text-blue-500 text-16 mt-3">gtb@credpal.com</h4>
        </div>

        <div class="flex justify-center mt-12">
          <button
            type="button"
            class="btn btn-blue py-4 px-14"
            @click="SubmitGtbMethod('ussd')"
          >
            <span class="text-sm">I've done it</span>
          </button>
        </div>
      </div>
    </modal>

    <modal
      className="w-11/12 sm:w-400px rounded md:w-400px"
      ref="verifyMobile"
    >
      <div class="lg:-mx-8">
        <h1 class="text-18 text-left font-bold mb-2">
          Verify with Mobile App
        </h1>

        <div class="rounded-sm bg-onboarding-900 text-white flex flex-row p-3">
          <div class="rounded-full h-6 w-6 bg-gtb"></div>
          <p class="text-10 mt-1 ml-3">
            Follow the steps below to verify your account.
          </p>
        </div>

        <GtbMobile />

        <div class="flex justify-center mt-12">
          <button
            type="button"
            class="btn btn-blue py-4 px-14"
            @click="SubmitGtbMethod('mobile')"
          >
            <span class="text-sm">I've done it</span>
          </button>
        </div>
      </div>
    </modal>


    <modal className="w-11/12 sm:w-400px rounded" ref="verifyMBS">
      <div class="md:-mx-8">
        <h1 class="text-18 text-left font-bold mb-3">
          Verify with MBS
        </h1>
        <div class="p-3 rounded-sm bg-onboarding-200 flex flex-row mb-5">
          <div class="w-10 mr-5">
            <svg
              width="53"
              height="56"
              viewBox="0 0 53 56"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g filter="url(#filter0_d)">
                <path
                  d="M25.688 39.5931C26.2015 39.864 26.7985 39.864 27.312 39.5931C29.776 38.293 37 33.9721 37 28V21.0286C37 20.174 36.457 19.4138 35.6486 19.1367L27.1486 16.2224C26.7282 16.0782 26.2718 16.0782 25.8514 16.2224L17.3514 19.1367C16.543 19.4138 16 20.174 16 21.0286V28C16 33.9721 23.224 38.293 25.688 39.5931Z"
                  fill="white"
                />
              </g>
              <defs>
                <filter
                  id="filter0_d"
                  x="0"
                  y="0.115234"
                  width="53"
                  height="55.682"
                  filterUnits="userSpaceOnUse"
                  color-interpolation-filters="sRGB"
                >
                  <feFlood flood-opacity="0" result="BackgroundImageFix" />
                  <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                  />
                  <feOffset />
                  <feGaussianBlur stdDeviation="8" />
                  <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0.404167 0 0 0 0 0.262215 0 0 0 0.32 0"
                  />
                  <feBlend
                    mode="normal"
                    in2="BackgroundImageFix"
                    result="effect1_dropShadow"
                  />
                  <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="effect1_dropShadow"
                    result="shape"
                  />
                </filter>
              </defs>
            </svg>
          </div>

          <p class="text-white text-9 text-left mt-3">
            Your information is highly secured. We’re registered and regulated
            by the CBN. Information requested will only be used to request your
            bank statement from your bank.
          </p>
        </div>
        <form @submit.prevent="submitMBS">
          <template v-if="getFormError(mbsform)">
            <div class="alert alert-red-soft mb-10">
              <span class="alert-icon">!</span>
              <span>{{ getFormError(mbsform) }}</span>
            </div>
          </template>

          <form-group
            leftIcon="mail-outline"
            name="bank_name"
            :form="mbsform"
            v-model="mbsform.data.bank_name.value"
            disabled
          >
            Bank Name
          </form-group>

          <form-group
            leftIcon="mail-outline"
            name="account_no"
            :form="mbsform"
            v-model="mbsform.data.account_no.value"
          >
            Account No
          </form-group>

          <form-group
            type="phone"
            leftIcon="call-outline"
            name="phone"
            :form="mbsform"
            v-model="mbsform.data.phone.value"
          >
            Phone Number Attached To Bank Account
          </form-group>

          <div class="flex justify-center">
            <button
              class="btn btn-blue font-normal py-4 px-16"
              type="submit"
              :disabled="mbsform.loading || bankStatementUploaded"
            >
              <sm-loader class="sm-loader-white" v-if="mbsform.loading" />
              <span v-else>Submit</span>
            </button>
          </div>
        </form>
      </div>
    </modal>
    <modal
      className="w-11/12 sm:w-400px rounded md:w-400px"
      ref="uploadBankStatement"
    >
      <div class="lg:-mx-8">
        <h1 class="text-18 text-left font-bold mb-2">
          Verify with Bank Statement
        </h1>
        <p class="text-12 mb-3 text-ash-700">
          Upload a 6-month statement of your bank account.
        </p>
        <div class="mt-10">
          <h1 class="text-12 text-left font-bold mb-2">
            Bank Statement
          </h1>
          <template v-if="getFormError(bankStatementForm)">
            <div class="alert alert-red-soft mb-10">
              <span class="alert-icon">!</span>
              <span>{{ getFormError(bankStatementForm) }}</span>
            </div>
          </template>
          <div class="flex flex-col">
            <dropzone
              class="mb-0 mr-2 w-full"
              v-model="bankStatementForm.data.bank_statement.value"
              accept=".pdf"
            />
            <div class="p-2 rounded-sm text-9 mt-3 bg-onboarding-300">
              <span class="text-ash-700 ml-3"
                >Your bank statement would determine your increased limit.</span
              >
            </div>
            <button
              type="button"
              class="btn btn-blue py-5 mx-16 mt-32"
              @click.prevent="submitBankStatement"
              :disabled="bankStatementForm.loading || bankStatementUploaded"
            >
              <sm-loader
                class="sm-loader-white"
                v-if="bankStatementForm.loading"
              />
              <span class="text-sm" v-else>Submit</span>
            </button>
            <div
              class="form-group-2-text text-red-500 mb-2"
              v-if="getFirstError('bank_statement', bankStatementForm)"
            >
              {{ getFirstError("bank_statement", bankStatementForm) }}
            </div>
          </div>
        </div>
      </div>
    </modal>
    <modal
      className="w-11/12 sm:w-400px rounded md:w-400px text-center"
      ref="successUploaded"
    >
      <img
        :src="assets.icons.lg.checkmark"
        class="mx-auto mb-8"
        height="90"
        alt="Check Mark"
      />
      <div class="text-lg font-bold mb-6">
        Success!
      </div>
      <div class="text-12 text-ash-800 font-normal mt-4 mb-4">
        Your bank statement was uploaded successfully. You will be contacted
        with your updated limits shortly.
      </div>
      <div class="flex justify-center">
        <button
          type="button"
          class="btn btn-blue py-3 mt-3 px-16"
          @click="closeModal"
        >
          Okay
        </button>
      </div>
    </modal>
  </div>
</template>
<script>
import { mapState } from "vuex";
export default {
  components: {
    GtbMobile: () => import("@/views/auth/components/GtbMobile.vue"),
  },
  data() {
    return {
      bankStatementForm: this.$options.basicForm([
        { name: "bank_statement", value: null },
      ]),
      mbsform: this.$options.basicForm([
        { name: "account_no", rules: "required|number|length:10" },
        { name: "bank_name", rules: "required" },
        { name: "phone", rules: "required|phone" },
      ]),
      bankName: "",
      checkMono: false,
      checkUssd: false,
      checkMobile: false,
      checkMbs: false,
      checkOkra: false,
      bankUpload: false,
      methodSelected: "",
    };
  },
  computed: {
    ...mapState(["banks", "mbsBanks", "okraBanks"]),
    bankOptions() {
      return this.banks.length
        ? this.banks.map((bank) => ({
            title: bank.name,
            value: bank.name,
          }))
        : [];
    },
    bankStatementUploaded() {
      return this.user?.documents?.find(
        (document) => document.type == "bank_statement"
      );
    },
    monoVerified() {
      return this.user?.documents?.find((document) => document.url === "mono");
    },
    mbsVerified() {
      return this.user?.documents?.find((document) => document.url === "mbs");
    },
    okraVerified() {
      return this.user?.documents?.find((document) => document.url === "okra");
    },
    ussdVerified() {
      return this.user?.documents?.find((document) => document.url === "ussd");
    },
    mobileVerified() {
      return this.user?.documents?.find(
        (document) => document.url === "mobile"
      );
    },
    manualVerified() {
      return (
        this.bankStatementUploaded &&
        !this.monoVerified &&
        !this.mbsVerified &&
        !this.okraVerified &&
        !this.ussdVerified &&
        !this.mobileVerified
      );
    },
    publicKey() {
      return "live_pk_d2X0CSU0qIZX9AH1ZwXV";
    },
  },
  methods: {
    async submitBankStatement() {
      if (!this.validateForm(this.bankStatementForm)) {
        return false;
      }

      this.bankStatementForm.error = null;

      this.bankStatementForm.loading = true;

      const data = new FormData();

      data.append(
        "bank_statement",
        this.bankStatementForm.data.bank_statement.value
      );

      await this.$post({
        url: `${this.$baseurl}/staff/documents/bank_statement/${this.user?.id}`,
        data,
        headers: {
          ...this.headers,
          "Content-Type": "application/json",
        },
        success: () => {
          this.$refs.successUploaded.open();
        },
        error: (error) => {
          this.bankStatementForm.error = error;
          this.mapFormErrors(
            this.bankStatementForm,
            error?.response?.data?.errors
          );
        },
      });
    },
    async submitMBS() {
      this.mbsform.loading = true;

      await this.$post({
        url: `${this.$baseurl}/staff/documents/bank-statement/mbs`,
        headers: this.headers,
        data: this.getFormData(this.mbsform),
        success: () => {
          this.resetForm(this.mbsform);
          this.$refs.verifyMBS.close();
          this.$refs.successUploaded.open();
        },
        error: (error) => {
          this.mbsform.error = error;
          this.mapFormErrors(this.mbsform, error?.response?.data?.errors);
        },
      });
      this.mbsform.loading = false;
    },
    async success(code) {
      await this.$put({
        url: `${this.$baseurl}/staff/documents/bank-statement/mono`,
        headers: this.headers,
        data: { code: code },
        success: () => {
          this.$refs.successUploaded.open();
        },
        error: (error) => {
          if (error?.response?.data?.success == false) {
            this.errorMessage = error?.response?.data?.message;
          }
        },
      });
    },
    async okraSuccess({ clientId }) {
      await this.$put({
        url: `${this.$baseurl}/staff/documents/bank-statement/okra`,
        headers: this.headers,
        data: { code: clientId },
        success: () => {
          this.$refs.successUploaded.open();
        },
        error: (error) => {
          console.log(error);
        },
      });
    },
    async SubmitGtbMethod(type) {
      await this.$get({
        url: `${this.$baseurl}/staff/documents/bank-statement/${type}`,
        headers: this.headers,
        success: () => {
         this.$root.loadSession();
        },
        error: (error) => {
          console.log(error);
        },
      });
    },
    closeModal() {
      this.$refs.successUploaded.close();
      this.$refs.uploadStatement.close();
      this.$root.loadSession();
    },
    verifyBank() {
      this.mbsform.data.bank_name.value = this.bankName;
      if (this.bankName === "Guaranty Trust Bank") {
        this.checkUssd = true;
        this.checkMobile = true;
        this.bankUpload = true;
        this.checkMbs = false;
        this.checkOkra = false;
        this.checkMono = false;
      } else if (
        this.mbsBanks.includes(this.bankName) &&
        this.okraBanks.includes(this.bankName)
      ) {
        this.checkMbs = true;
        this.checkMono = true;
        this.checkOkra = true;
        this.bankUpload = true;
        this.checkUssd = false;
        this.checkMobile = false;
      } else if (this.mbsBanks.includes(this.bankName)) {
        this.checkMbs = true;
        this.checkMono = true;
        this.checkOkra = false;
        this.bankUpload = true;
        this.checkUssd = false;
        this.checkMobile = false;
      } else if (this.okraBanks.includes(this.bankName)) {
        this.checkMono = true;
        this.checkOkra = true;
        this.checkMbs = false;
        this.bankUpload = true;
        this.checkUssd = false;
        this.checkMobile = false;
      } else {
        this.bankUpload = true;
        this.checkMono = false;
        this.checkMbs = false;
        this.checkOkra = false;
        this.checkUssd = false;
        this.checkMobile = false;
      }
    },
  },
};
</script>